import { createContext, useContext, useEffect, useState } from 'react'

import { addDoc, serverTimestamp, useDoc } from 'lib/firestore'

const JobContext = createContext()

const JOB_STATUS = Object.freeze({
  WAITING_JOB: 'WAITING_JOB', // BigQuery の job 完了待ち
  PENDING: 'PENDING', // 処理待ち
  READY: 'READY', // 準備完了
  RUNNING: 'RUNNING', // 処理中
  DONE: 'DONE', // 完了
  ERROR: 'ERROR', // エラー
})

export function DownloadJobProvider({ children }) {
  const [job, setJob] = useState(null)
  const { item } = useDoc(`segments/${job?.segment.id}/download-jobs/${job?.id}`)
  const running = job && (job.id !== item?.id || !['DONE', 'ERROR'].includes(item?.state))

  useEffect(() => {
    if (!item) return
    if (item.id !== job.id) return
    switch (item.state) {
      case 'DONE':
        job.resolve(item)
        break
      case 'ERROR':
        job.reject(item.error)
        break
    }
  }, [item, job])

  const registerDownloadJob = async data => {
    const now = serverTimestamp()
    return addDoc(`segments/${data.segment.id}/download-jobs`, {
      ...data,
      state: JOB_STATUS.READY,
      createdAt: now,
      updatedAt: now,
    }).then(doc => {
      return new Promise((resolve, reject) => {
        setJob({ id: doc.id, segment: data.segment, resolve, reject })
      })
    })
  }

  return (
    <JobContext.Provider value={{ job: item, running, registerDownloadJob }} children={children} />
  )
}

export default function useDownloadJob() {
  return useContext(JobContext)
}
