import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import FormHelperText from '@mui/material/FormHelperText'
import MenuItem from '@mui/material/MenuItem'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Stack from '@mui/material/Stack'
import dayjs from 'dayjs'

import { Form, Select, SubmitButton, TextField } from 'components/Form'

const format = data => {
  const values = structuredClone(data)
  values.sendAt = dayjs(values.sendAt).format('YYYY-MM-DDTHH:mm')
  return values
}

export default function ExportDialog({ data, onSubmit, onClose }) {
  const [open, setOpen] = useState(false)
  const [tabIndex, setTabIndex] = useState(0)

  useEffect(() => {
    setOpen(true)
  }, [])

  const handleChangeTab = (_, newValue) => {
    setTabIndex(newValue)
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} TransitionProps={{ onExited: onClose }}>
      <Tabs
        sx={{ m: 2 }}
        textColor="secondary"
        indicatorColor="secondary"
        value={tabIndex}
        onChange={handleChangeTab}
      >
        <Tab label="IMS 連携" />
        <Tab label="ダウンロード" />
      </Tabs>
      <TabPanel value={tabIndex} index={0}>
        <IMSForm data={data} onSubmit={onSubmit} onCancel={() => setOpen(false)} />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <DownloadForm onSubmit={onSubmit} onCancel={() => setOpen(false)} />
      </TabPanel>
    </Dialog>
  )
}

const TabPanel = ({ children, value, index }) => {
  return <Box sx={{ display: value === index ? 'block' : 'none' }}>{children}</Box>
}

const IMSForm = ({ data, onSubmit, onCancel }) => {
  const form = useForm({ defaultValues: format(data) })

  const handleSubmit = values => {
    onSubmit({ type: 'IMS', values: { ...values, sendAt: new Date(values.sendAt) } })
  }

  return (
    <Form form={form} onSubmit={handleSubmit}>
      <DialogContent>
        <Stack spacing={2}>
          <Select
            name="external_list_owner_group_id"
            label="外部リスト所有グループ"
            fullWidth
            defaultValue="IMSCS本部／BPSC"
          >
            <MenuItem value="IMSCS本部／BPSC">IMSCS本部／BPSC</MenuItem>
            <MenuItem value="IMS日経ID 抽出担当者">IMS日経ID 抽出担当者</MenuItem>
            <MenuItem value="IMS日経ID 分析担当者">IMS日経ID 分析担当者</MenuItem>
            <MenuItem value="IMS販売局 抽出担当者">IMS販売局 抽出担当者</MenuItem>
            <MenuItem value="IMS販売局 分析担当者">IMS販売局 分析担当者</MenuItem>
            <MenuItem value="IMS文事 抽出担当者">IMS文事 抽出担当者</MenuItem>
            <MenuItem value="IMSイベレジ 抽出担当者">IMSイベレジ 抽出担当者</MenuItem>
          </Select>
          <TextField
            name="memo"
            label="外部リスト利用 用途"
            rules={{ maxLength: 200 }}
            errorText="200 文字までで入力して下さい"
            fullWidth
            multiline
          />
          <TextField
            name="sendAt"
            type="datetime-local"
            label="連携日時"
            rules={{ required: true }}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          {data.id && (
            <FormHelperText error>
              未実行のジョブが存在します。設定を保存すると既存のジョブが上書きされます。
            </FormHelperText>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={() => onCancel()}>
          キャンセル
        </Button>
        <SubmitButton color="inherit" sx={{ fontWeight: 'bold' }}>
          設定する
        </SubmitButton>
      </DialogActions>
    </Form>
  )
}

const DownloadForm = ({ onSubmit, onCancel }) => {
  const form = useForm()

  const handleSubmit = values => {
    onSubmit({ type: 'DOWNLOAD', values })
  }

  return (
    <Form form={form} onSubmit={handleSubmit}>
      <DialogContent>
        <Stack spacing={2}>
          <Select name="fieldType" label="対象カラム" fullWidth defaultValue="serial">
            <MenuItem value="serial">SERIAL ID</MenuItem>
            <MenuItem value="phone">電話番号（ハッシュ値）</MenuItem>
            <MenuItem value="email">メールアドレス（ハッシュ値）</MenuItem>
          </Select>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={() => onCancel()}>
          キャンセル
        </Button>
        <SubmitButton color="inherit" sx={{ fontWeight: 'bold' }}>
          ダウンロード
        </SubmitButton>
      </DialogActions>
    </Form>
  )
}
